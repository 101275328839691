<template>
  <div class="container mt-5">
    <div class="row">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <div class="row bortr mt-4">
      <div class="col-sm-12">
        <h1 class="text-center">Tax Invoice / فاتورة ضريبية</h1>
      </div>
    </div>
    <div class="row bortr">
      <div class="col-sm-6 p-0">
        <table class="table m-0">
          <tbody>
            <tr class="no-border">
              <td>Invoice No:</td>
              <td>{{ invoice.invoice_id }}</td>
              <td class="text-right">رقم الفاتورة</td>
            </tr>
            <tr class="no-border">
              <td>Reference No</td>
              <td>{{ invoice.ref_invoice }}</td>
              <td class="text-right">رقم المرجع</td>
            </tr>
            <tr class="no-border">
              <td>Invoice Issue Date:</td>
              <td>{{ invoice.created_at | moment("DD MMM YYYY") }}</td>
              <td class="text-right">تاريخ الفاتورة</td>
            </tr>
            <tr class="no-border">
              <td>Invoice Period:</td>
              <td>
                {{ invoice.invoice_start | moment("DD MMM YYYY") }} to
                {{ invoice.invoice_end | moment("DD MMM YYYY") }}
              </td>
              <td class="text-right">مــدة الفاتورة</td>
            </tr>

            <tr class="no-border">
              <td>Service Order No. </td>
              <td>{{ invoice.costcenter }}</td>
              <td class="text-right">الرقم المرجعي الداخلي</td>
            </tr>
            <tr class="no-border">
              <td>Purchase Order No. </td>
              <td>{{ invoice.po }}</td>
              <td class="text-right">امر شراء</td>
            </tr>
            <tr class="no-border">
              <td>Vendor no.</td>
              <td>{{ invoice.vendor }}</td>
              <td class="text-right">رقم المزود</td>
            </tr>
            <tr class="no-border">
              <td>Contract No</td>
              <td>{{ invoice.contract_no }}</td>
              <td class="text-right">عقد رقم</td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="col-sm-6">
        <div class="mt-4 float-center ">
          <vue-qrcode :value="invoice.qrcode" :options="{ width: 400 }"></vue-qrcode>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table m-0">
        <tbody>
          <tr class="no-border top-boder">
            <td class="text-left">Seller</td>
            <td class="text-center"></td>
            <td class="text-right td-3">: البائع</td>
            <td class="text-left">Buyer</td>
            <td class="text-center"></td>
            <td class="text-right">:المشتري</td>
          </tr>

          <tr class="no-border">
            <td class="text-left">Company Name</td>
            <td class="text-center fa-nb">
              شركة ابناء عبدالرحمن على البصيلى
              <br>
              AbdulRahman Ali AlBusaili Sons Co
            </td>
            <td class="text-right td-3">اسم الشركة</td>
            <td class="text-left">Company Name</td>
            <td class="text-center fa-nb">{{ cus_company }}
              <!-- Khafi Joint Operation / عمليات الخفجي المشتركة -->
            </td>
            <td class="text-right">اسم الشركة</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">VAT ID</td>
            <td class="text-center fa-nb">300047805100003</td>
            <td class="text-right td-3">رقم شهادة ضريبة القيمة المضافة</td>
            <td class="text-left">VAT ID</td>
            <!-- <td class="text-center fa-nb">310197988100003</td> -->
            <td class="text-center fa-nb">{{ cus_vat }}</td>
            <td class="text-right">رقم شهادة ضريبة القيمة المضافة</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">Building No</td>
            <td class="text-center fa-nb">7529</td>
            <td class="text-right td-3">رقم المبني</td>
            <td class="text-left">Building No</td>
            <!-- <td class="text-center">-</td> -->
            <td class="text-center">{{ cus_building_no }}</td>
            <!-- <td class="text-center fa-nb">KJO /‫المشتركة‬ ‫الخفجي‬ ‫عمليات‬</td> -->
            <td class="text-right">رقم المبني</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">Street Name</td>
            <td class="text-center fa-nb">Salah Aldin Al-Ayoubi/صلاح الدين الايوبي</td>
            <td class="text-right td-3">إسم الشارع</td>
            <td class="text-left">Street Name</td>
            <!-- <td class="text-center">-</td> -->
            <td class="text-center">{{ cus_street_name }}</td>
            <!-- <td class="text-center fa-nb">King Faisal Street ‫فيصل‬ ‫الملك‬ ‫شارع‬/ </td> -->
            <td class="text-right">إسم الشارع</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">District</td>
            <td class="text-center fa-nb">Al-Zahraa /الزهراء</td>
            <td class="text-right td-3">الحي</td>
            <td class="text-left">District</td>
            <td class="text-center fa-nb">{{ cus_district }}</td>
            <!-- <td class="text-center fa-nb">Al-Mohamadiya /‫المحمدية‬ ‫حي‬ </td> -->
            <td class="text-right">الحي</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">City</td>
            <td class="text-center fa-nb">Riyadh / ‫الرياض‬</td>
            <td class="text-right td-3">المدينة</td>
            <td class="text-left">City</td>
            <td class="text-center fa-nb">AL-Khafji/ ‫الخفجي‬</td>
            <td class="text-right">المدينة</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">Country</td>
            <td class="text-center fa-nb">KSA/ ‫السعودية‬</td>
            <td class="text-right td-3">الدولة</td>
            <td class="text-left">Country</td>
            <td class="text-center fa-nb"> KSA/ ‫السعودية‬</td>
            <td class="text-right">الدولة</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">Postal Code</td>
            <td class="text-center fa-nb">12811</td>
            <td class="text-right td-3">الرمزي البريدي</td>
            <td class="text-left">Postal Code</td>
            <!-- <td class="text-center fa-nb">31971</td> -->
            <td class="text-center fa-nb">{{ cus_postal_code }}</td>
            <td class="text-right">الرمزي البريدي</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">Additional No.</td>
            <td class="text-center fa-nb">2071</td>
            <td class="text-right td-3">الرقم الضافي</td>
            <td class="text-left">Additional No.</td>
            <!-- <td class="text-center fa-nb">-</td> -->
            <td class="text-center fa-nb">{{ cus_additional_no }}</td>
            <td class="text-right">الرقم الضافي</td>
          </tr>
          <tr class="no-border">
            <td class="text-left">CR Number</td>
            <td class="text-center fa-nb">1010152299</td>
            <td class="text-right td-3">رقم السجل التجاري</td>
            <td class="text-left">CR Number.</td>
            <!-- <td class="text-center fa-nb">87712</td> -->
            <!-- <td class="text-center">-</td> -->
            <td class="text-center">{{ cus_cr_no }}</td>
            <td class="text-right">رقم السجل التجاري</td>
          </tr>
          <tr class="no-border top-boder">
            <td class="text-left" colspan="3">Line Items:</td>
            <td class="text-right" colspan="3">البند</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <table class="table m-0 w-100">
        <tbody>
          <tr class="row-tr">
            <td class="text-center">Description الوصف</td>
            <td class="text-center">Amount (USD) / قيمة الفاتورة بالدولار</td>
            <td class="text-center">Amount (SAR) / قيمة الفاتورة بالريال</td>
          </tr>
          <tr class="row-tr" v-for="(value, index) in items" :key="index">
            <td class="text-left fa-nb">
              {{ value.item }} / {{ value.item_arabic }}
            </td>
            <td class="text-center fa-nb">{{ comma(value.absent) }}
              <!-- usd -->
            </td>
            <td class="text-center fa-nb">{{ comma(value.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row bortr mt-4">
      <table class="table m-0 bortr">
        <tbody>
          <tr class="no-border bortr">
            <td colspan="3">AMOUNT IN DOLLARS / قيمة الفاتورة بالدولار</td>
            <td colspan="3" class="text-right">
              AMOUNT IN SAR / قيمة الفاتورة بالريال
            </td>
          </tr>
          <tr>
            <td>TOTAL AMOUNT IN USD / اجمالي قيمة الفاتورة بالدولار</td>
            <td>USD</td>
            <td>{{ invoice_total_amount_us }}</td>
            <td>TOTAL AMOUNT IN SAR / اجمالي قيمة الفاتورة بالريال</td>
            <td>SAR</td>
            <td>{{ invoice_total_amount }}</td>
          </tr>
          <tr>
            <td>
              15% VALUE ADDED TAX (VAT) IN USD / قيمة الضربية المضافة 15 %
              بالدولار
            </td>
            <td>USD</td>
            <td>{{ invoice_vat_amount_us }}</td>
            <td>
              15% VALUE ADDED TAX (VAT) IN SAR / قيمة الضربية المضافة 15 % بالريال
            </td>
            <td>SAR</td>
            <td>{{ invoice_vat_amount }}</td>
          </tr>
          <tr>
            <td>
              NET AMOUNT PAYABLE IN USD / اجمالي قيمة الفاتورة مع قيمة الضربية
              المضافة بالدولار
            </td>
            <td>USD</td>
            <td>
              <h2 class="fa-bold"> {{ invoice_gross_amount_us }} </h2>
            </td>
            <td>
              NET AMOUNT PAYABLE IN SAR / اجمالي قيمة الفاتورة مع قيمة الضربية
              المضافة بالريال
            </td>
            <td>SAR</td>
            <td><h2 class="fa-bold"> {{ invoice_gross_amount }} </h2></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <table class="table footer-table no-border">
        <tbody>
          <tr>
            <td colspan="1" class="text-left">Amount in Words: {{ invoice.amount_words }}</td>
            <td colspan="1" class="text-right"> المبلغ بالكلمات :{{ invoice.amount_words_ar }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoice",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,

      invoice_total_amount_us: 0,
      invoice_vat_amount_us: 0,
      invoice_gross_amount_us: 0,

      hc_address: "",

      cus_company: '',
      cus_vat: '',
      cus_building_no: '',
      cus_street_name: '',
      cus_district: '',
      // cus_city: '',
      // cus_country: '',
      cus_postal_code: '',
      cus_additional_no: '',
      cus_cr_no: '',
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    sum(n1) {
      let vat = Number(n1) * 0.15;
      let total = Number(n1) + vat;
      return this.comma(total);
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          //   console.log(response.data)
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];
          // let total_amount = 0;
          let total_amount_us = 0;

          this.items.forEach((x) => {
            // this.invoice_total_amount
            // total_amount += Number(x.amount);
            total_amount_us += Number(x.absent);
          });
          // let vat_invoice = total_amount * 0.15;
          // let gross_total = total_amount + vat_invoice;

          let vat_invoice_us = total_amount_us * 0.15;
          let gross_total_us = total_amount_us + vat_invoice_us;


          // this.invoice_total_amount = this.comma(total_amount);
          // this.invoice_vat_amount = this.comma(vat_invoice);
          // this.invoice_gross_amount = this.comma(gross_total);

          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)

          this.invoice_total_amount_us = this.comma(total_amount_us);
          this.invoice_vat_amount_us = this.comma(vat_invoice_us);
          this.invoice_gross_amount_us = this.comma(gross_total_us);

          if (this.invoice.customer == "Aljhalami Company") {
            this.cus_company = "Aljhalami Company"
            this.cus_vat = "300396094400003	";
            this.cus_building_no = "2057";
            this.cus_street_name = "Al Amir Nayif Bin Abdul Aziz / شارع الامير نايف بن عبدالعزيز";
            this.cus_district = "1st Industrial Area / المنطقة الصناعية الاولى";
            this.cus_postal_code = "39253";
            this.cus_additional_no = "8947";
            this.cus_cr_no = "2057002822";
          }
          else {
            this.cus_company = "Khafi Joint Operation / عمليات الخفجي المشتركة"
            this.cus_vat = "310197988100003	";
            this.cus_building_no = "-";
            this.cus_street_name = "-";
            this.cus_district = "Al-Mohamadiya / المحمدية حي";
            this.cus_postal_code = "31971";
            this.cus_additional_no = "-";
            this.cus_cr_no = "-";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.fa-bold {
  font-weight: bold;
}

.float-center {
  text-align: center;
}

.table tr td {
  font-size: 18px !important;
  font-weight: bold;
}

.bortr {
  border: black solid 1px;
}

.no-border {
  border-left: black solid 1px;
  border-right: black solid 1px;
}

.right-border {
  border-right: black solid 1px !important;
}

.top-boder {
  border-top: solid 1px black !important;
}

.bottom-boder {
  border-bottom: solid 1px black !important;
}

.table td {
  border: solid 1px black;
  font-size: 18px;
}

.td-3 {
  border-right: solid 1px black !important;
}

.row-tr td {
  border: black solid 1px;
}

.fa-nb {
  /* font-weight: 300 !important; */
  font-weight: bold;
}

.image {
  width: 100px;
  align-self: center;
}

.header-arabic {
  align-self: flex-end;
  font-size: 18px;
  font-weight: bold;
  width: 300px;
  border: 1px solid #000;
}

.al-right {
  align-self: flex-end;
}

.df {
  display: flex;
  align-items: center;
}

/* .center {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.text-sm {
  font-size: 12px;
  font-weight: bold;
}

.text-md {
  font-size: 16px;
}

.footer-table td {
  border: black thin solid;
  max-width: 50%;
  width: 50%;
  font-weight: bold;
}

.footer-table {
  width: 100%;
}
</style>